const base_bid_score = {
    "7": 5,
    "8": 10,
    "9": 20,
    "sol": 50,
    "10": 40,
    "pure-sol": 100,
    "11": 80,
    "12": 160,
    "13": 320,
}

export function calculateScore(bid, relative_score, num_vip, vip_suit, knock, re_knock, score_unit=1) {
    if (bid === null) {
        return null
    }

    relative_score = parseInt(relative_score)

    let score = base_bid_score[bid.base_bid]

    if (bid.hasExtra() && bid.extra_bid !== "normal") {
        score *= 2
    }

    if (bid.extra_bid === "vip") {
        score *= num_vip

        const double_vip_suits = ["clubs", "joker", "sans"]
        if (double_vip_suits.includes(vip_suit)) {
            score *= 2
        }
    }

    if (knock) {
        score *= 2
        if (re_knock) {
            score *= 2
        }
    }

    if (relative_score >= 0) {
        score *= (relative_score + 1)
    } else {
        score *= relative_score * 2
    }

    return score * score_unit
}