<template>
    <b-form-group>
      <b-button-group v-for="(partition, p_index) in partitions" :key="p_index" style="display: flex">
        <b-button
          size="lg"
          style="flex: 1"
          v-for="(option, index) in partition"
          :key="index"
          @click="selected_option = option; $emit('change', option)"
          :class="{ 'active': option === selected_option, 'not-active': option !== selected_option  }"
        >{{ label(option) }}</b-button>
      </b-button-group>
    </b-form-group>
</template>

<script>

import { partition } from "../util/arraytools"

export default {
  name: "RadioSelector",
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    labels: {
      type: Object,
      required: false,
    },
    num_partitions: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  data: function() {
    return {
      selected_option: this.value,
    }
  },
  computed: {
    partitions: function() {
      const chunk_size = Math.floor(this.options.length / this.num_partitions)
      return partition(this.options, chunk_size)
    },
  },
  methods: {
    label: function(option) {
      return this.labels && this.labels[option] ? this.labels[option] : option
    }
  },
}
</script>

<style scoped>
.not-active {
    color: #3276b1;
    background-color: #fff;
}
</style>

