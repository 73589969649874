<template>
  <div>
    <bid-selector v-model="selected_bid"></bid-selector>
    <div v-if="selected_bid">
      <div v-if="selected_bid.hasExtra()">
        <hr />
        <p>Hvor mange gik den op eller ned?</p>
        <p class="text-center relative-score">{{ relative_score_human(relative_score) }}</p>
        <b-row>
          <b-col cols="2" style="text-align: right">{{ relative_score_human(relative_score_min) }}</b-col>
          <b-col>
            <b-form-input
              type="range"
              :min="relative_score_min"
              :max="relative_score_max"
              v-model="relative_score"
              size="lg"
            ></b-form-input>
          </b-col>
          <b-col cols="2" style="text-align: left">{{ relative_score_human(relative_score_max) }}</b-col>
        </b-row>


        <div v-if="selected_bid.extra_bid == 'vip'">
          <hr />
          <p>Hvor mange blev der vippet?</p>
          <radio-selector
            v-model="vip"
            :options="['1','2','3']"
          ></radio-selector>

          <p>Hvilken kulør blev vippet?</p>
          <radio-selector
            v-model="vip_suit"
            :options="['spades', 'hearts', 'clubs', 'diamonds', 'sans']"
            :labels="{'spades': 'Spar ♠', 'hearts': 'Hjerter ♥', 'clubs': 'Klør ♣', 'diamonds': 'Ruder ♦', 'sans': 'Sans'}"
            :num_partitions="2"
          ></radio-selector>
        </div>
      </div>
      <div v-else>
        <hr />
        <p>Gik den hjem?</p>
        <radio-selector
          v-model="relative_score"
          :options="[-1, 0]"
          :labels="{'-1': 'Nej', '0': 'Ja'}"
        ></radio-selector>
      </div>

      <hr />

      <b-form-checkbox v-model="knock" size="lg">Banket?</b-form-checkbox>
      <b-form-checkbox v-if="knock" v-model="re_knock" size="lg">Re-banket?</b-form-checkbox>
    </div>
  </div>
</template>

<script>
import BidSelector from "./BidSelector.vue";
import RadioSelector from "./RadioSelector.vue";

import { calculateScore } from "../util/score";

export default {
  name: "ScoreCalculator",
  data: function () {
    return {
      selected_bid: null,
      relative_score: 0,
      relative_score_min: -13,
      relative_score_max: 13,
      vip: "1",
      vip_suit: "spades",
      knock: false,
      re_knock: false,
      score_unit: 1,
    };
  },
  watch: {
    selected_bid: function() {
      const t = this.selected_bid.requiredTricks()
      this.relative_score_min = -t
      this.relative_score_max = 13 - t
    },
    score: function() {
      this.$emit("update", this.score)
    },
  },
  computed: {
    score: function () {
      return calculateScore(
        this.selected_bid,
        this.relative_score,
        parseInt(this.vip),
        this.vip_suit,
        this.knock,
        this.re_knock,
        this.score_unit
      );
    },
  },
  methods: {
    relative_score_human: function(relative_score) {
      relative_score = parseInt(relative_score)
      if (relative_score === 0) {
        return "0"
      } else if (relative_score > 0) {
        return relative_score + " op"
      } else { // relative_score < 0
        return (-relative_score) + " ned"
      }
    },
  },
  components: {
    "bid-selector": BidSelector,
    "radio-selector": RadioSelector,
  },
};
</script>

<style scoped>
.relative-score {
  font-weight: bold;
  font-size: 2em;
}
</style>
