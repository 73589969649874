<template>
  <div>
    <radio-selector
      v-model="base_bid"
      :options="base_bid_order"
      :labels="base_bid_label"
      :num_partitions="3"
    ></radio-selector>
    <hr v-if="hasExtra(base_bid)" />
    <radio-selector
      v-if="hasExtra(base_bid)"
      v-model="extra_bid"
      :options="extra_bid_order"
      :labels="extra_bid_label"
      :num_partitions="2"
    ></radio-selector>
  </div>
</template>

<script>
import RadioSelector from "./RadioSelector.vue"

import {
  base_bid_order,
  base_bid_label,
  extra_bid_order,
  extra_bid_label,
  default_extra_bid,
  getBid,
  hasExtra,
} from "../util/bid"

export default {
  name: "BidSelector",
  props: ["value"],
  model: {
    prop: "value",
    event: "change"
  },
  data: function() {
    return {
      base_bid: null,
      extra_bid: null,
    }
  },
  watch: {
    base_bid: function() {
      this.assignDefaultExtra()
      this.emit()
    },
    extra_bid: function() {
      this.emit()
    },
  },
  methods:  {
    hasExtra: hasExtra,
    assignDefaultExtra: function() {
      if (hasExtra(this.base_bid) && !this.extra_bid) {
        this.extra_bid = default_extra_bid
      }
    },
    emit: function() {
      this.$emit("change", getBid(this.base_bid, this.extra_bid))
    },
  },
  created: function() {
    this.base_bid_order = base_bid_order
    this.base_bid_label = base_bid_label

    this.extra_bid_order = extra_bid_order
    this.extra_bid_label = extra_bid_label
  },
  components: {
    "radio-selector": RadioSelector
  }
}
</script>

<style scoped>
.not-active {
    color: #3276b1;
    background-color: #fff;
}
</style>
