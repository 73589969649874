export class Bid {
  base_bid
  extra_bid

  constructor(base_bid, extra_bid) {
    this.base_bid = base_bid
    this.extra_bid = extra_bid
  }

  requiredTricks() {
    if (this.hasExtra(this.base_bid)) {
      return parseInt(this.base_bid)
    } else {
      switch (this.base_bid) {
        case "sol": return 1
        case "pure-sol": return 0
        default: return 0
      }
    }
  }

  hasExtra() {
    return hasExtra(this.base_bid)
  }

  toString() {
    return JSON.stringify({
      base_bid: this.base_bid,
      extra_bid: this.extra_bid,
    })
  }
}

export const base_bid_order = [
  "7",
  "8",
  "9",
  "sol",
  "10",
  "pure-sol",
  "11",
  "12",
  "13",
]

export const base_bid_label = {
  "7": "7",
  "8": "8",
  "9": "9",
  "sol": "Sol",
  "10": "10",
  "pure-sol": "Ren sol",
  "11": "11",
  "12": "12",
  "13": "13",
}

export const extra_bid_order = [
  "normal",
  "clubs",
  "halves",
  "vip",
  "sans",
]

export const extra_bid_label = {
  "normal": "Alm.",
  "clubs": "Klør",
  "halves": "Halve",
  "vip": "Vip",
  "sans": "Sans",
}

export const default_extra_bid = extra_bid_order[0]

export function getBid(base_bid, extra_bid) {
  if (hasExtra(base_bid)) {
    extra_bid = extra_bid ? extra_bid : default_extra_bid
  } else {
    extra_bid = null
  }
  return new Bid(base_bid, extra_bid)
}

export function hasExtra(base_bid) {
  return !isNaN(parseInt(base_bid))
}

export function calculateScore(bid, relative_score, score_unit = 1) {
  return 1 * score_unit
}