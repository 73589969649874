<template>
  <div id="container">
    <h2>Melding:</h2>
    <score-calculator @update="score = $event"></score-calculator>

    <hr />

    <h2>Takst:</h2>
    <p>{{ score }}</p>
  </div>
</template>

<script>

import ScoreCalculator from './components/ScoreCalculator.vue'

export default {
  name: 'App',
  data: function() {
    return {
      score: null,
    }
  },
  components: {
    "score-calculator": ScoreCalculator,
  }
}
</script>

<style scoped>
#container {
  padding: 1em;
}
</style>