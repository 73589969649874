export function partition(array, chunk) {
    if (chunk <= 0) {
        return [array]
    }

    let i, j, temporary, partitions = []
    for (i = 0, j = array.length; i < j; i += chunk) {
        temporary = array.slice(i, i + chunk);
        partitions.push(temporary)
    }

    return partitions
}